<template>
  <div>
    <home-top></home-top>
    <a-row :gutter="[8,16]">
      <a-col :span="8">
        <a-card :bordered="false">
          <template slot="title">
            待调度工单
            <a-badge
              count="4"
              :number-style="{
                  backgroundColor: '#fff',
                  color: '#999',
                  boxShadow: '0 0 0 1px #d9d9d9 inset',
                }"
            />
          </template>
          <a-table
            :scroll="{ y: 460 }"
            :columns="columns"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
          >
            <template slot="_index" slot-scope="text, record, index">
              {{index + 1 }}
            </template>
            <template slot="type" slot-scope="text, record">
              <a-tag v-if="record.type==='shangbao'" color="cyan">上报</a-tag>
              <a-tag v-if="record.type==='xunjian'" color="green">巡检</a-tag>
              <a-tag v-if="record.type==='qita'" color="purple">其他</a-tag>
            </template>
            <template slot="state" slot-scope="text, record">
              <a-tag v-if="record.state==='UN_PROCESS'">未分配</a-tag>
              <a-tag v-if="record.state==='PROCESSING'" color="green">已分配</a-tag>
            </template>
            <template slot="action" slot-scope="text, record">
              <a @click="handleClick(record.id)" @click.stop>调度</a>
            </template>
          </a-table>
        </a-card>
      </a-col>
      <a-col :span="16">
        <a-card :bordered="false">
          <template slot="title">
            处理中的工单
            <a-badge
              count="20"
              :number-style="{
                  backgroundColor: '#fff',
                  color: '#999',
                  boxShadow: '0 0 0 1px #d9d9d9 inset',
                }"
            />
          </template>
          <!--<template slot="extra">
            <a-radio-group size="small">
              <a-radio-button value="c">
                未处理
              </a-radio-button>
              <a-radio-button value="d">
                已处理
              </a-radio-button>
            </a-radio-group>
          </template>-->
          <a-table
            :scroll="{ y: 460 }"
            :columns="columns"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
          >
            <template slot="_index" slot-scope="text, record, index">
              {{index + 1 }}
            </template>
            <template slot="type" slot-scope="text, record">
              <a-tag v-if="record.type==='shangbao'" color="cyan">上报</a-tag>
              <a-tag v-if="record.type==='xunjian'" color="green">巡检</a-tag>
              <a-tag v-if="record.type==='qita'" color="purple">其他</a-tag>
            </template>
            <template slot="state" slot-scope="text, record">
              <a-tag v-if="record.state==='UN_PROCESS'">未分配</a-tag>
              <a-tag v-if="record.state==='PROCESSING'" color="green">已分配</a-tag>
            </template>
            <template slot="action" slot-scope="text, record">
              <a @click="handleClick(record.id)" @click.stop>调度</a>
            </template>
          </a-table>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card :bordered="false">
          <template slot="title">
            空闲车辆
            <a-badge
              count="4"
              :number-style="{
                  backgroundColor: '#fff',
                  color: '#999',
                  boxShadow: '0 0 0 1px #d9d9d9 inset',
                }"
            />
          </template>
          <!--<template slot="extra">
            <a-radio-group>
              <a-radio-button value="c">
                空闲
              </a-radio-button>
              <a-radio-button value="d">
                执行中
              </a-radio-button>
            </a-radio-group>
          </template>-->
          <a-table
            :columns="columns"
            :scroll="{ y: 460 }"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
          >
            <template slot="_index" slot-scope="text, record, index">
              {{index + 1 }}
            </template>
            <template slot="type" slot-scope="text, record">
              <a-tag v-if="record.type==='shangbao'" color="cyan">上报</a-tag>
              <a-tag v-if="record.type==='xunjian'" color="green">巡检</a-tag>
              <a-tag v-if="record.type==='qita'" color="purple">其他</a-tag>
            </template>
            <template slot="state" slot-scope="text, record">
              <a-tag v-if="record.state==='UN_PROCESS'">未分配</a-tag>
              <a-tag v-if="record.state==='PROCESSING'" color="green">已分配</a-tag>
            </template>
            <template slot="action" slot-scope="text, record">
              <a @click="handleClick(record.id)" @click.stop>调度</a>
            </template>
          </a-table>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="[16,16]">
      <a-col :span="6"/>
      <a-col :span="6"/>
      <a-col :span="6"/>
      <a-col :span="6"/>
    </a-row>
  </div>
</template>

<script>
  import HomeTop from './HomeTop'

  const columns = [
    {
      title: '#',
      width: 60,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '工单名称',
      dataIndex: 'name',
      align: 'center',
      key: 'name'
    },
    {
      title: '类型',
      dataIndex: 'age',
      align: 'center',
      key: 'age',
      width: 80,
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '状态',
      dataIndex: 'address',
      key: 'address 1',
      align: 'center',
      ellipsis: true,
      scopedSlots: { customRender: 'state' }
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' }
    },
  ]

  const data = [
    {
      key: '1',
      name: '围栏被破坏',
      type: 'shangbao',
      state: 'UN_PROCESS',
    },
    {
      key: '2',
      name: '车辆维修',
      type: 'qita',
      state: 'PROCESSING',
    },
    {
      key: '3',
      name: '1号设备发现异响',
      type: 'xunjian',
      state: 'UN_PROCESS',
    },
    {
      key: '3',
      name: '1号设备发现异响',
      type: 'xunjian',
      state: 'UN_PROCESS',
    },
    {
      key: '3',
      name: '1号设备发现异响',
      type: 'xunjian',
      state: 'UN_PROCESS',
    },
    {
      key: '3',
      name: '1号设备发现异响',
      type: 'xunjian',
      state: 'UN_PROCESS',
    },
    {
      key: '3',
      name: '1号设备发现异响',
      type: 'xunjian',
      state: 'UN_PROCESS',
    },
    {
      key: '3',
      name: '1号设备发现异响',
      type: 'xunjian',
      state: 'UN_PROCESS',
    },
    {
      key: '3',
      name: '1号设备发现异响',
      type: 'xunjian',
      state: 'UN_PROCESS',
    },
    {
      key: '3',
      name: '1号设备发现异响',
      type: 'xunjian',
      state: 'UN_PROCESS',
    },
    {
      key: '3',
      name: '1号设备发现异响',
      type: 'xunjian',
      state: 'UN_PROCESS',
    },
    {
      key: '3',
      name: '1号设备发现异响',
      type: 'xunjian',
      state: 'UN_PROCESS',
    },
    {
      key: '3',
      name: '1号设备发现异响',
      type: 'xunjian',
      state: 'UN_PROCESS',
    },
  ]

  export default {
    name: 'workbenchHome',
    components: { HomeTop },
    data () {
      return {
        data,
        columns,
      }
    },
  }
</script>

<style scoped>

</style>